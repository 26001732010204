<template>
  <div
    ref="wrapper"
    class="wrapper"
  >
    <div
      v-if="user"
      v-on="$listeners"
    >
      <v-badge
        dot
        offset-y=10
        offset-x=10
        color="secondary"
        v-model="displayWarning"
      >
        <!-- <template v-slot:badge>
          <v-icon
            size=18
            style="height: 100%"
          >{{$icon('i.ExclamationThick')}}</v-icon>
        </template> -->
        <v-chip
          :disabled="disabled"
          class="my-1 mr-1"
          :close="close"
          @click:close="propagateClose"
        >
          <user-ref
            v-if="!internalUser.id && internalUser.email"
            :fullname="internalUser.fullname"
            :email="internalUser.email"
            hide-email
            hide-phone
            hide-group
            class="ml-n2 mr-2"
            :left="popupLeft"
            :nudge-bottom="popupNudgeBottom"
            :nudge-left="popupNudgeLeft"
            :nudge-right="popupNudgeRight"
            :nudge-top="popupNudgeTop"
            :attach="wrapper"
          >
            <template v-slot:popup>
              <v-card class="pa-2 ma-0">
                <user-ref
                  :email="internalUser.email"
                  :fullname="internalUser.fullname"
                  :hide-email="!internalUser.isAccountContact && hideEmailIfUser"
                  block-popup
                  class="mr-3"
                  hide-group
                  is-popup
                />
                <v-list v-if="contactCanBeAdded">
                  <v-list-item>
                    <v-list-item-content>
                      <picker
                        document-types="contact-groups"
                        class="mb-4"
                        :selected-id.sync="contactGroupId"
                        :clearable="false"
                        :label="$t('t.ContactGroup')"
                        :attach="false"
                        hide-message
                        ref="picker-group"
                      />
                      <v-btn
                        @click="addUser"
                        color="primary"
                        :disabled="!contactGroupId"
                      >
                        <v-icon
                          small
                          class="mr-2"
                        >{{$icon('i.AddAccountContact')}}</v-icon>
                        {{$t('t.AddAccountContact')}}
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </template>
          </user-ref>
          <user-ref
            v-else-if="internalUser.id"
            hide-email
            hide-phone
            hide-group
            class="ml-n2 mr-2"
            :id="internalUser.id"
            :left="popupLeft"
            :nudge-bottom="popupNudgeBottom"
            :nudge-left="popupNudgeLeft"
            :nudge-right="popupNudgeRight"
            :nudge-top="popupNudgeTop"
            :attach="wrapper"
            :document-type="isExternalUser ? cacheType : undefined"
          >
            <template v-slot:popup>
              <v-card class="pa-2 ma-0">
                <user-ref
                  :document-type="isExternalUser ? cacheType : undefined"
                  :hide-email="!internalUser.isAccountContact && hideEmailIfUser"
                  :id="internalUser.id"
                  block-popup
                  class="mr-3"
                  is-popup
                />
                <v-divider class="mt-3" />

                <v-list>
                  <v-list-item v-if="contactCanBeAdded">
                    <v-list-item-content>
                      <picker
                        document-types="contact-groups"
                        class="mb-4"
                        :selected-id.sync="contactGroupId"
                        :clearable="false"
                        :label="$t('t.ContactGroup')"
                        :attach="false"
                        hide-message
                        ref="picker-group"
                      />
                      <v-btn
                        @click="addUser"
                        color="primary"
                        :disabled="!contactGroupId"
                      >
                        <v-icon
                          small
                          class="mr-2"
                        >{{$icon('i.AddAccountContact')}}</v-icon>
                        {{$t('t.AddAccountContact')}}
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="internalUser.consultationDate">
                    <v-list-item-content>
                      <v-list-item-subtitle>{{$t('t.ReadAt')}} {{formatDate(internalUser.consultationDate)}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-list-item v-if="warnNoConsultation">
                  <v-list-item-content>
                    <v-list-item-subtitle class="pt-2">
                      <v-badge
                        dot
                        color="secondary"
                        v-model="displayWarning"
                      >
                        <span>{{$t('t.NotYetConsulted')}}</span>
                      </v-badge>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </user-ref>
          <div v-if="internalUser.userRoleName">
            <span
              v-if="!internalUser.id"
              class="error--text mr-1"
            >{{$t('t.NotFound')}}</span>
            <span>({{internalUser.userRoleName}})</span>
          </div>
          <v-checkbox
            v-if="requiredCheckBox"
            :disabled="readonly"
            class="mb-3"
            hide-details
            v-model="isRequired"
          />
          <!-- <span @click="openPopUp">{{internalUser.fullname}}</span> -->
        </v-chip>
      </v-badge>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      wrapper: null,
      contactGroupId: null
    }
  },
  components: {
    Picker: () => import('@/components/picker'),
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  computed: {
    displayWarning () {
      return this.warnNoConsultation || this.contactCanBeAdded
    },
    cacheType () {
      return this.externalUserCacheType
    },
    internalUser () {
      const u = Object.assign({}, this.user, { fullname: this.user.fullname })
      if (!this.isExternalUser && u.userId) {
        u.id = u.userId
      }
      return u
    },
    contactCanBeAdded () {
      return !this.disabledAddContact && (this.isExternalUser && !this.$store.getters.currentUser?.settings?.emailsSystem.some(p => p === this.user.email))
    },
    isExternalUser () {
      return this.user.userId === null
    },
    isRequired: {
      get () {
        return this.internalUser.isRequired
      },
      set (isRequired) {
        this.$emit('update:user', Object.assign({}, this.user, { isRequired }))
      }
    }
  },
  async mounted () {
    this.wrapper = this.attach ?? await this.$waitFor(() => this.$refs.wrapper)
  },
  props: {
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    externalUserCacheType: Number,
    user: Object,
    disabled: Boolean,
    readonly: Boolean,
    close: {
      type: Boolean,
      default: true
    },
    hideEmailIfUser: Boolean,
    popupLeft: Boolean,
    popupNudgeBottom: { type: [String, Number], default: 8 },
    popupNudgeLeft: [String, Number],
    popupNudgeRight: [String, Number],
    popupNudgeTop: [String, Number],
    requiredCheckBox: Boolean,
    warnNoConsultation: Boolean,
    disabledAddContact: Boolean
  },
  methods: {
    propagateClose (e) {
      this.$emit('click:close', e)
    },
    addUser () {
      this.$emit('apply',
        {
          fullName: this.user.fullname,
          email: this.user.email,
          contactGroupId: this.contactGroupId
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position relative
</style>
